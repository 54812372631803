<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div class="create_user_div">
      <router-link :to="{path : '/backoffice/backoffice_user_edit', query : {'id' : 0}}">Create New backoffice User.</router-link>
    </div>
    <div v-for="backoffice_user in backoffice_users_res" :key="backoffice_user.id" class="item_list">
      <div>
        {{backoffice_user.email}}
      </div>
      <div class="item_action">
        <router-link :to="{path : '/backoffice/backoffice_user_delete', query : {'id' : backoffice_user.id}}">Delete</router-link> |
        <router-link :to="{path : '/backoffice/backoffice_user_edit', query : {'id' : backoffice_user.id}}">Edit</router-link> |
        <router-link :to="{path : '/backoffice/backoffice_user_change_password', query : {'id' : backoffice_user.id}}">Change Password</router-link>
      </div>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeUsers',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop : true,
      backoffice_users_res : []
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    let backoffice_users_res = await backendModel.backendRequest("/Api/service/backoffice/backoffice_get_users", {})
    this.backoffice_users_res = backoffice_users_res.data.backoffice_users_res
    this.loader_prop = false
    console.log(this.backoffice_users_res)
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>
